import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "navbar-nav mr-2 mb-2 mb-lg-0" }
const _hoisted_2 = {
  key: 1,
  style: {"color":"#3c8baf","margin-right":"5px"}
}
const _hoisted_3 = {
  key: 2,
  class: "nav-item dropdown mr-4"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  id: "mySidenav",
  class: "sidenav2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_ctx.adminEntrance)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          style: {"font-size":"30px","cursor":"pointer","color":"white","margin-right":"10px"},
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openNav && _ctx.openNav(...args)))
        }, " ☰ "))
      : _createCommentVNode("", true),
    (_ctx.adminEntrance)
      ? (_openBlock(), _createElementBlock("li", _hoisted_2, _toDisplayString(_ctx.getUserName), 1))
      : _createCommentVNode("", true),
    (_ctx.adminEntrance)
      ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
          _createElementVNode("img", {
            style: {"height":"40px"},
            src: _ctx.getProfilePicUrl,
            class: "rounded-circle",
            alt: "",
            loading: "lazy"
          }, null, 8, _hoisted_4)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("li", null, [
      (!_ctx.adminEntrance)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            style: {"color":"grey","cursor":"pointer"},
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.signInWithGoogle && _ctx.signInWithGoogle(...args))),
            class: "fas fa-user-circle fa-2x"
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("li", null, [
      (_ctx.adminEntrance)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            style: {"color":"grey","cursor":"pointer"},
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args))),
            class: "fas fa-sign-out-alt fa-2x"
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("a", {
        href: "javascript:void(0)",
        class: "closebtn",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.closeNav && _ctx.closeNav(...args)))
      }, " × "),
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode(" Головна ")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        class: "dropdown-item",
        to: "/add_user",
        exact: ""
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode("Додати категорію фото ")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        class: "dropdown-item",
        to: "/list",
        exact: ""
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [
          _createTextVNode(" Список категорій фото ")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        class: "dropdown-item",
        to: "/add_history",
        exact: ""
      }, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [
          _createTextVNode(" Додати фото в Історію ")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        class: "dropdown-item",
        to: "/list_history",
        exact: ""
      }, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [
          _createTextVNode(" Список фото в Історіі ")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        class: "dropdown-item",
        to: "/add_text_bible",
        exact: ""
      }, {
        default: _withCtx(() => _cache[9] || (_cache[9] = [
          _createTextVNode(" Додати вірш Біблії ")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        class: "dropdown-item",
        to: "/list_text_bible",
        exact: ""
      }, {
        default: _withCtx(() => _cache[10] || (_cache[10] = [
          _createTextVNode(" Список віршів Біблії ")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        class: "dropdown-item",
        to: "/add_text_poetry",
        exact: ""
      }, {
        default: _withCtx(() => _cache[11] || (_cache[11] = [
          _createTextVNode(" Додати вірш ")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        class: "dropdown-item",
        to: "/list_text_poetry",
        exact: ""
      }, {
        default: _withCtx(() => _cache[12] || (_cache[12] = [
          _createTextVNode(" Список віршів ")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        class: "dropdown-item",
        to: "/add_new_book",
        exact: ""
      }, {
        default: _withCtx(() => _cache[13] || (_cache[13] = [
          _createTextVNode(" Додати книгу ")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        class: "dropdown-item",
        to: "/add_folder_google_disc",
        exact: ""
      }, {
        default: _withCtx(() => _cache[14] || (_cache[14] = [
          _createTextVNode(" Додати папку в Архів ")
        ])),
        _: 1
      }),
      _cache[17] || (_cache[17] = _createElementVNode("a", {
        class: "dropdown-item",
        href: "https://squoosh.app/index.html",
        target: "_blank"
      }, " Сжати фото ", -1)),
      _createVNode(_component_router_link, {
        class: "dropdown-item",
        to: "/add_good",
        exact: ""
      }, {
        default: _withCtx(() => _cache[15] || (_cache[15] = [
          _createTextVNode("Додати подію ")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        class: "dropdown-item",
        to: "/list_goods",
        exact: ""
      }, {
        default: _withCtx(() => _cache[16] || (_cache[16] = [
          _createTextVNode(" Список подій ")
        ])),
        _: 1
      })
    ])
  ]))
}